import React from "react";
import Head from "next/head";
import ErrorPage from "@templates/ErrorPage";

const ErrorPage404 = ({}) => {
  return (
    <>
      <Head>
        <title>
          {`404 Error - We can't seem to find the page you're looking for -
          Realworld`}
        </title>
      </Head>
      <main>
        <ErrorPage
          emoji="/images/ThinkingEmoji.png"
          header="it's not you, it's us"
          subheader="We can't seem to find the page you're looking for."
          errorMessage="error code: 404"
        />
      </main>
    </>
  );
};

export default ErrorPage404;

ErrorPage404.displayName = "404";
