import React, { useEffect, useState, forwardRef } from "react";
import * as S from "./styles";

// lottie-react documentation: https://lottiereact.com/
import Lottie from "lottie-react";

const MagicImage = forwardRef(function MagicImage(props, ref) {
  const { src, alt, className, onLoad, loop = false, id, dataCy } = props;
  const [jsonData, setJsonData] = useState(false);
  const [lottieLoaded, setLottieLoaded] = useState(false);

  useEffect(() => {
    if (isLottie(src) && typeof src === "string") {
      fetch(src)
        .then((res) => res.json())
        .then((out) => {
          onLottieLoad();
          setJsonData(out);
        })
        .catch((e) => {
          // TODO: handle sitewide error logging
          console.log(e);
        });
    } else if (isLottie(src) && typeof src === "object") {
      setJsonData(src);
      onLottieLoad();
    }
  }, [src]);

  const isLottie = (img) => {
    if (typeof img === "object" || img.match(".json$", "i")) {
      return true;
    }
    return false;
  };

  const onLottieLoad = () => {
    setLottieLoaded(true);
    if (onLoad) {
      onLoad();
    }
  };

  // lottie
  if (isLottie(src) && jsonData) {
    return (
      <S.LottieWrapper_div lottieLoaded={lottieLoaded} className={className}>
        <Lottie
          animationData={jsonData}
          aria-label={alt}
          id={id}
          role="img"
          loop={loop}
          // ref={ref} // not sure if ref works with lottie, uncomment if needed in future
          data-cy={dataCy ? dataCy : "magic-image-lottie"}
        />
      </S.LottieWrapper_div>
    );
  }

  // default image type
  if (!isLottie(src)) {
    return (
      <img
        src={src}
        alt={alt}
        className={className}
        id={id}
        {...(onLoad ? { onLoad: onLoad } : {})}
        ref={ref}
        data-cy={dataCy ? dataCy : "magic-image-default"}
      />
    );
  }

  return null;
});

export default MagicImage;
