import styled from "styled-components";
import MagicText from "@shared/atoms/MagicText";
import MagicImage from "@shared/atoms/MagicImage";

export const StyledErrorPage = styled.div`
  height: 90vh;
  display: grid;
  grid-template-rows: 16px 1fr;
  padding: 40px;
`;

export const LogoContainer = styled.div`
  width: 132px;
  @media ${(props) => props.theme.device.tablet} {
    width: 199px;
  }
`;

export const Emoji_img = styled(MagicImage)`
  height: 160px;
  width: 160px;
  margin-bottom: 48px;
  @media ${(props) => props.theme.device.tablet} {
    margin-bottom: 0px;
  }
`;

export const TextContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  text-align: center;
  @media ${(props) => props.theme.device.tablet} {
    text-align: left;
  }
`;

export const Subheader = styled(MagicText)`
  max-width: 275px;
  justify-self: center;
  @media ${(props) => props.theme.device.tablet} {
    max-width: 289px;
    justify-self: start;
  }
`;

export const ButtonContainer = styled.div`
  width: 228px;
`;

export const InnerContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  @media ${(props) => props.theme.device.tablet} {
    grid-template-rows: 160px;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 85px;
    margin-top: 148px;
    justify-self: center;
  }
`;

export const TopRow = styled.div`
  align-self: end;
  justify-self: center;
  @media ${(props) => props.theme.device.tablet} {
    align-self: center;
  }
`;

export const BottomRow = styled.div`
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media ${(props) => props.theme.device.tablet} {
    display: grid;
    align-self: center;
    grid-template-rows: 1fr 16px;
    grid-gap: 24px;
  }
`;
