import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import MagicText from "@shared/atoms/MagicText";
import SVG from "@shared/atoms/SVG";
import BrandButton from "@shared/atoms/BrandButton";
import { theme } from "@constants/theme";

const ErrorPage = ({ emoji, errorMessage, header, button, subheader }) => {
  return (
    <S.StyledErrorPage>
      <S.LogoContainer>
        <SVG name="HorizontalLogo" color={theme.colors.grayscale.charcoal} />
      </S.LogoContainer>
      <S.InnerContainer>
        <S.TopRow>
          <S.Emoji_img src={emoji} />
        </S.TopRow>
        <S.BottomRow>
          <S.TextContainer>
            <MagicText type="h1">{header}</MagicText>
            <S.Subheader type="p1">{subheader}</S.Subheader>
          </S.TextContainer>
          {errorMessage ? (
            <MagicText type="caption1">{errorMessage}</MagicText>
          ) : null}
          {button ? (
            <S.ButtonContainer>
              <BrandButton
                text={button.text}
                href={button.target}
                onClick={button.onClick}
              />
            </S.ButtonContainer>
          ) : null}
        </S.BottomRow>
      </S.InnerContainer>
    </S.StyledErrorPage>
  );
};

ErrorPage.propTypes = {
  emoji: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  button: PropTypes.shape({
    target: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }),
};

export default ErrorPage;
